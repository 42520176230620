import * as React from "react";
import { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "gatsby";
import Activation from "../components/activation";
import Layout from "../components/layout";
import {
  section,
  beigeShape,
  contactInfo,
  sandShape,
  container,
  listItem,
  paragraph,
} from "./contact.module.css";
import { Helmet } from "react-helmet";
import { SEO } from "../components/seo";

const ContactPage = () => {
  function initializeHubspotForm() {
    if ("hbspt" in window) {
      window.hbspt.forms.create({
        region: "eu1",
        portalId: "26262171",
        formId: "88c294ed-3e4f-4e21-95e5-10addefe01d9",
        version: "V2_PRERELEASE",
        target: "#formContainer",
      });
    } else {
      setTimeout(initializeHubspotForm, 500);
    }
  }

  useEffect(() => {
    initializeHubspotForm();
  });

  return (
    <Layout pageTitle="Contact us">
      <Container fluid className={container}>
        <div className={beigeShape} />
        <div className={sandShape} />

        <div>
          {/* <iframe
						class="meetings-iframe-container"
						style={{ width: '100%', height: '750px' }}
						src="https://meetings-eu1.hubspot.com/cesare-marchesan-rensburg?embed=true"
					>
						{' '}
					</iframe> */}

          <Helmet>
            {/* <script
							type="text/javascript"
							src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
						/> */}
          </Helmet>
        </div>

        <Container fluid className={section}>
          <Row>
            <Col md={6}>
              <Container fluid>
                <h1>Request a demo</h1>
                <p className={paragraph}>
                  We're happy to show you how Floatist can help your business.
                </p>
                <ul className="list-unstyled">
                  <li className={listItem}>
                    <i className="fa-solid fa-check fa-lg" /> Get an elaborate
                    demo of the Floatist platform
                  </li>
                  <li className={listItem}>
                    <i className="fa-solid fa-check fa-lg" /> Learn how Floatist
                    can help you to manage your charter operations, fleet, and
                    improve your customer experience.
                  </li>
                  <li c lassName={listItem}>
                    <i className="fa-solid fa-check fa-lg" /> Get pricing
                    information and learn how to save costs with Floatist
                  </li>
                </ul>
                <h3>Contact us</h3>
                <i
                  className="fa-brands fa-whatsapp"
                  style={{ paddingRight: "8px" }}
                />
                Whatsapp:{" "}
                <Link className={contactInfo} to="https://wa.me/447822013131">
                  +44 7822 013131
                </Link>
                <br />
                <i
                  className="fa-regular fa-phone"
                  style={{ paddingRight: "8px" }}
                />
                Phone:{" "}
                <Link className={contactInfo} to="tel:0034871559345">
                  +34 87155 9345
                </Link>
                <br />
                <i
                  className="fa-regular fa-envelope"
                  style={{ paddingRight: "8px" }}
                />
                <Link className={contactInfo} to="mailto:hello@floatist.com">
                  hello@floatist.com
                </Link>
                <br />
                <br />
              </Container>
            </Col>
            <Col md={6}>
              <div id="formContainer">
                <Helmet>
                  <script
                    charset="utf-8"
                    type="text/javascript"
                    src="//js-eu1.hsforms.net/forms/v2.js"
                  ></script>
                </Helmet>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Activation title="You can do it, we can help" />
    </Layout>
  );
};

export default ContactPage;

export const Head = () => (
  <SEO>
    <script type="application/ld+json">
      {`
            {
            "@context": "https://floatist.com",
            "@type": "Organization",
            "url": "https://www.floatist.com",
            "name": "Floatist",
            "logo": "https://www.floatist.com/images/logo.png",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+442036950846",
                "contactType": "Sales"
            }
            }
        `}
    </script>
  </SEO>
);
